import axios from 'axios';
import { getCookie } from "../Tool/CookieUtils";

const apiUrl = process.env.REACT_APP_LOCAL_HOST;
const urlList = process.env.REACT_APP_URL_BASE_LISTURL;
const baseUrl = apiUrl+urlList;

const getAllUrls = async () => {
 
  const response = await axios.get(baseUrl);
  return response.data;
};

const createUrl = async (newUrl) => {
  const token = getCookie('token')
  const response = await axios.post(baseUrl, newUrl, {
    headers:{
        Authorization:`Bearer ${token}`
    }
  });
  return response.data;
};

const updateUrl = async (id, updatedUrl) => {
  const token = getCookie('token')  
  const response = await axios.put(`${baseUrl}/${id}`, updatedUrl,{
    headers:{
        Authorization:`Bearer ${token}`
    }
  });
  return response.data;
};

const deleteUrl = async (id) => {
  const token = getCookie('token')  
  const response = await axios.delete(`${baseUrl}/${id}`,{
    headers:{
        Authorization:`Bearer ${token}`
    }
  });
  return response.data;
};

export default { getAllUrls, createUrl, updateUrl, deleteUrl };
