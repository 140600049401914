import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Store/AuthContext";
import { motion, AnimatePresence } from "framer-motion";
import Admin from "../Assets/admin.png";
import User from "../Assets/users.png";
import SuperAdmin from "../Assets/SuperAdmin.png";
import { getCookie } from "../Tool/CookieUtils";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import urlService from '../service/UrlService'; 
import { useModal } from "../Store/NavbarModalStyleContext"; // Import useModal

Modal.setAppElement('#root');  

const Navbar = () => {
  const { currentUser, logout, isLoggedIn } = useAuth();
  const { isModalOpen, turnOnModal, turnOffModal } = useModal(); // Gunakan useModal
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [urls, setUrls] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUrls, setFilteredUrls] = useState([]);
  const dropdownRef = useRef(null);
  const [userRole, setUserRole] = useState("");
  const token = getCookie("token");
  const location = useLocation();

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserRole(decoded.role);
      } catch (error) {
        console.error("Invalid token specified:", error);
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchUrls = async () => {
      try {
        const fetchedUrls = await urlService.getAllUrls();
        setUrls(fetchedUrls);
      } catch (error) {
        toast.error("Error fetching URLs");
        console.error("Error fetching URLs:", error);
      }
    };

    fetchUrls();
  }, []);

  useEffect(() => {
    setFilteredUrls(
      urls.filter(url => url.judul.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm, urls]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openModal = () => {
    turnOnModal(); // Gunakan turnOnModal untuk membuka modal
  };

  const closeModal = () => {
    turnOffModal(); // Gunakan turnOffModal untuk menutup modal
  };

  const getRoleImage = () => {
    switch(userRole) {
      case "admin":
        return Admin;
      case "superadmin":
        return SuperAdmin;
      default:
        return User;
    }
  };

  return (
    <nav className="bg-dark-primary text-white p-4 w-full sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex flex-row">
          <Link
            to="/"
            className={`${location.pathname === "/" ? "border-b-2" : ""} text-white px-4 py-2 hover:text-accent transition-colors duration-300`}
          >
            Home
          </Link>
          {isLoggedIn && userRole === "superadmin" && (
            <Link
              to="/recording"
              className={`${location.pathname === "/recording" ? "border-b-2" : ""} text-white px-4 py-2 hover:text-accent transition-colors duration-300`}
            >
              Recording
            </Link>
          )}
          <button
            onClick={openModal}
            className="text-white px-4 py-2 hover:text-accent transition-colors duration-300"
          >
            Links
          </button>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="URL Modal"
            className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
          >
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-3xl w-full">
              <button
                onClick={closeModal}
                className="text-black float-right"
              >
                &times;
              </button>
              <h2 className="text-2xl mb-4">Cari URL</h2>
              <input
                type="text"
                placeholder="Cari URL"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-2 py-1 text-black border rounded w-full mb-4"
              />
              <ul className="max-h-96 overflow-y-auto">
                {filteredUrls.map((url) => (
                  <li
                    key={url._id}
                    className="py-2 px-4 border-b hover:bg-gray-200"
                  >
                    <a
                      href={url.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black block break-words"
                    >
                      {url.judul}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
        {currentUser ? (
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="bg-dark-primary flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-dark-primary focus:ring-secondary"
              id="user-menu"
              aria-haspopup="true"
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={getRoleImage()}
                alt={userRole.charAt(0).toUpperCase() + userRole.slice(1)}
              />
            </button>
            <AnimatePresence>
              {isDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className="absolute right-0 z-50 bg-dark-primary mt-2 w-48 rounded-md shadow-lg py-1 bg-secondary ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-white hover:bg-dark-secondary hover:text-text-dark"
                    role="menuitem"
                  >
                    Profile
                  </Link>
                  {userRole === "admin" || userRole === "superadmin" ? (
                    <>
                      <Link
                        to="/log-user"
                        className="block px-4 py-2 text-sm text-white hover:bg-dark-secondary hover:text-text-dark"
                        role="menuitem"
                      >
                        Log Users
                      </Link>
                      {userRole === "superadmin" && (
                        <div>
                          <Link
                          to="/register"
                          className="block px-4 py-2 text-sm text-white hover:bg-dark-secondary hover:text-text-dark"
                          role="menuitem"
                        >
                          Register
                          
                        </Link>
                        <Link
                          to="/url-penting"
                          className="block px-4 py-2 text-sm text-white hover:bg-dark-secondary hover:text-text-dark"
                          role="menuitem"
                        >
                          Link Manager
                          
                        </Link>
                        </div>
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                  <button
                    onClick={logout}
                    className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-dark-secondary hover:text-text-dark"
                    role="menuitem"
                  >
                    Logout
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ) : (
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link
              to="/login"
              className="text-white px-4 py-2 hover:text-accent transition-colors duration-300"
            >
              Login
            </Link>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
