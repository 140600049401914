import React, { useEffect, useState } from "react";
import { registerUser, adminRegisterUser } from "../service/UsersService";
import { useNotification } from "../Store/NotificationContext";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../Tool/CookieUtils";
import { jwtDecode } from "jwt-decode";
import Loading from "../components/UI/Loading";
import {
  UserIcon,
  MailIcon,
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import {
  FaUserTag 
} from "react-icons/fa";
import PasswordBar from "./UI/PasswordBar";
import PasswordValidator from "./UI/PasswordChecklist";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inisial, setInisial] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  const [typeRegistrationAdmin, setRegistrationAdmin] = useState(false);
  const token = getCookie("token");
  const [roleRegistration, setRoleRegistration] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);

  useDocumentTitle('Register')

  const handleOptionChange = (e) => {
    setRoleRegistration(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (userRole === "superadmin") {
        await adminRegisterUser(username, email, password, roleRegistration,inisial );
        showNotification("Registration successful", "success");
        setTimeout(() => {
          navigate("/log-user");
        }, 3000);
      } else {
        await registerUser(username, email, password, inisial);
        showNotification("Registration successful", "success");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      showNotification(error.response?.data?.error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserRole(decoded.role);
        setRegistrationAdmin(decoded.role === "superadmin");
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token]);

  return (
    <div className="flex flex-col items-center py-5 w-full justify-center">
      <h1 className="text-2xl mb-4 text-gray-700">Register</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-md w-full max-w-md"
      >
        <div className="mb-4 relative">
          <label htmlFor="username" className="block text-gray-700">
            Username
          </label>
          <div className="absolute inset-y-0 left-0 pl-3 mt-5 flex items-center pointer-events-none">
            <UserIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="block w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
            required
          />
        </div>
        <div className="mb-4 relative">
          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <div className="absolute inset-y-0 left-0 pl-3  mt-5 flex items-center pointer-events-none">
            <MailIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
            required
          />
        </div>
        <div className="mb-4 relative">
          <label htmlFor="inisial" className="block text-gray-700">
            Inisial
          </label>
          <div className="absolute inset-y-0 left-0 pl-3  mt-5 flex items-center pointer-events-none">
            <FaUserTag className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            maxLength={4}
            id="inisial"
            name="inisial"
            value={inisial}
            onChange={(e) => setInisial(e.target.value)}
            className="block w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
            required
          />
        </div>
        <div className="mb-4 relative">
          <label htmlFor="password" className="block text-gray-700">
            Password
          </label>
          <div className="absolute inset-y-0 left-0 pl-3  mt-5 flex items-center pointer-events-none">
            <LockClosedIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full pl-10 pr-10 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
            required
          />
          <div
            className="absolute inset-y-0 right-0 pr-3  mt-5 flex items-center cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOffIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" />
            )}
          </div>
        </div>
        {/* confirm password */}
        <div className="mb-4 relative">
          <label htmlFor="password" className="block text-gray-700">
            Confirm Password
          </label>
          <div className="absolute inset-y-0 left-0 pl-3  mt-5 flex items-center pointer-events-none">
            <LockClosedIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="password"
            name="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="block w-full pl-10 pr-10 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
            required
          />
          <div
            className="absolute inset-y-0 right-0 pr-3  mt-5 flex items-center cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showPassword ? (
              <EyeOffIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" />
            )}
          </div>
        </div>

        <PasswordBar password={password} />
        <div className="text-xs pb-9 ">
          <PasswordValidator
            password={password}
            confirmPassword={confirmPassword}
            onChange={(isValid) => setIsValidPassword(isValid)}
          />
        </div>
        {typeRegistrationAdmin && (
          <div className="mb-4">
            <label className="block text-gray-700">Role</label>
            <div className="flex justify-between mt-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="option"
                  value="user"
                  checked={roleRegistration === "user"}
                  onChange={handleOptionChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">User</span>
              </label>
              <label className="inline-flex items-center mt-2">
                <input
                  type="radio"
                  name="option"
                  value="admin"
                  checked={roleRegistration === "admin"}
                  onChange={handleOptionChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Admin</span>
              </label>
              <label className="inline-flex items-center mt-2">
                <input
                  type="radio"
                  name="option"
                  value="superadmin"
                  checked={roleRegistration === "superadmin"}
                  onChange={handleOptionChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Super Admin</span>
              </label>
            </div>
          </div>
        )}

        
        <div className="flex items-center justify-center">
          {isLoading ? (
            <Loading width={40} height={40} />
          ) : (
            <button
              type="submit"
              disabled={!isValidPassword}
              className={`w-full ${
                isValidPassword ? `` : "cursor-not-allowed"
              } bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300`}
            >
              Register
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Register;
