import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchAllUsers, updateUser, deleteUser } from "../service/UsersService";
import Loading from "../components/UI/Loading";
import { useNotification } from "../Store/NotificationContext";
import ConfirmationPopup from "../components/UI/ConfirmationPopup";
import { toast } from "react-toastify";
import useDocumentTitle from '../hooks/useDocumentTitle';
import { AgGridReact } from 'ag-grid-react';
import '../CSS/modalEditUser.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
 
const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isModalCurrentUserOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { showNotification } = useNotification();
  const [showPopup, setShowPopup] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userToDelete, setUserNametoDelete] = useState(null);
  const [Blur, setBlur] = useState(false) 


  useDocumentTitle('Log User');

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const data = await fetchAllUsers();
        setUsers(data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching users");
        showNotification("Error fetching users :(", "error");
        setLoading(false);
      }
    };

    fetchUsers();
  }, [showNotification]);

  const handleEditClick = (user) => {
    setCurrentUser(user);
    setModalOpen(true);
  };

  const handleDeleteClick = (userId, username) => {
    setUserIdToDelete(userId);
    setUserNametoDelete(username);
    setShowPopup(true);
  };

  const handleCancelDelete = () => {
    setBlur(false);
    setShowPopup(false);
    setUserIdToDelete(null); // Reset state
  };

  const handleConfirmDelete = async () => {
    const deleteUserPromise = deleteUser(userIdToDelete);
    toast.promise(deleteUserPromise, {
      pending: 'Menghapus user...',
      success: 'User berhasil dihapus!',
      error: {
        render({ data }) {
          return data?.response?.data?.message || 'Error menghapus user';
        }
      }
    });
    try {
      await deleteUserPromise;
      setUsers(users.filter((user) => user.id !== userIdToDelete));
      setUserIdToDelete(null); // Reset state
      setUserNametoDelete(null);
      setShowPopup(false);
      setBlur(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        console.log(error.response.data.message, "error");
        setBlur(false);
      } else {
        console.log("Error deleting user.", "error");
        setBlur(false);
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentUser(null);
    setBlur(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updateUserPromise = updateUser(currentUser.id, {
      username: currentUser.username,
      email: currentUser.email,
      role: currentUser.role,
    });

    toast.promise(updateUserPromise, {
      pending: 'Mengupdate...',
      success: 'User berhasil diupdate !',
      error: {
        render({ data }) {
          return data?.response?.data?.message || 'Error Update User';
        }
      }
    });
    try {
      await updateUserPromise;
      setUsers(
        users.map((user) => (user.id === currentUser.id ? currentUser : user))
      );
      setLoading(false);
      handleModalClose();
      setBlur(false)
    } catch (error) {
      setBlur(false)
      console.log("Error updating user.", "error");
    }
  };

  const handleEditAction = (params) => {
    setBlur(true)
    handleEditClick(params.data);
  };

  const handleDeleteAction = (params) => {
    setBlur(true)
    handleDeleteClick(params.data.id, params.data.username);
   
  };

  const columnDefs = [
    { headerName: "Nama", field: "username" },
    { headerName: "Email", field: "email" },
    { headerName: "Role", field: "role" },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div className="flex justify-center space-x-2">
          <button
            onClick={() => handleEditAction(params)}
            className="rounded-md border-2 text-sm px-1 py-1  max-w-20 bg-blue-500  hover:bg-blue-800 text-white"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteAction(params)}
            className="rounded-md border-2 text-sm px-1 py-1 max-w-20 bg-red-500  hover:bg-red-800 text-white"
          >
            Delete
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={` flex flex-col items-center px-4 w-full py-6`}>
      {error && (
        <p className="text-center text-white py-2 rounded-md shadow-2xl bg-red-700 w-full flex items-center justify-center">
          {error}
        </p>
      )}

      <h1 className={`${Blur ? "blur":""} w-full text-left text-4xl font-bold mb-6`}>
        Tabel User
      </h1>
      {isLoading ? (
        <Loading width={90} height={90} />
      ) : (
        <div className={`${Blur ? "blur":""} w-full ag-theme-alpine`} style={{ height: 500, width: '100%' }}>
          <AgGridReact
            rowData={users}
            columnDefs={columnDefs}
            pagination
           
          />
        </div>
      )}

      {currentUser && (
        <Modal
          isOpen={isModalCurrentUserOpen}
          onRequestClose={handleModalClose}
          contentLabel="Edit User"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h1 className="text-2xl font-bold mb-4">Edit User</h1>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            <div>
              <label htmlFor="username" className="block font-medium">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={currentUser.username}
                onChange={handleInputChange}
                className="input"
              />
            </div>
            <div>
              <label htmlFor="email" className="block font-medium">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={currentUser.email}
                onChange={handleInputChange}
                className="input"
              />
            </div>
            <div>
              <label htmlFor="role" className="block font-medium">Role:</label>
              <select
                id="role"
                name="role"
                value={currentUser.role}
                onChange={handleInputChange}
                className="input"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="superadmin">Super Admin</option>
              </select>
            </div>
            {isLoading ? (
              <Loading width={52} height={52} />
            ) : (
              <div className="flex space-x-4">
                <button type="submit" className="px-2 py-1 bg-blue-500 rounded-md hover:bg-blue-700 max-w-16 hover:text-white ">
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleModalClose}
                  className="px-2 py-1 bg-slate-300 rounded-md hover:bg-slate-600 max-w-16 hover:text-white"
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </Modal>
      )}

      {showPopup && (
        <ConfirmationPopup
          message={`Apakah yakin ingin menghapus ${userToDelete} ?`}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default UserTable;
