import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../Tool/CookieUtils";
import { addTemplate } from "../service/TemplateService";
import Loading from "../components/UI/Loading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-toastify";
import { TextField, Autocomplete, Chip } from '@mui/material';
import useDocumentTitle from '../hooks/useDocumentTitle';

function AddTemplate() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate(); 
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  useDocumentTitle('Tambah - Template');

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      navigate("/login");
      return;
    }
  }, [token, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const addTemplatePromise = addTemplate(title, description, tags);

    toast.promise(addTemplatePromise, {
      pending: 'Menambahkan...',
      success: 'Template berhasil ditambahkan!',
      error: {
        render({ data }) {
          return data?.response?.data?.message || 'Error menambahkan template';
        }
      }
    }); 
    try {
      await addTemplatePromise;
      setLoading(false);
      navigate("/");
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (html) => {
    setDescription(html);
  };

  return (
    <div className="container mx-auto p-4"> 
      <h1 className="text-3xl font-bold mb-4">Add Template</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="title" className="block text-sm font-bold mb-2">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-bold mb-2">
            Description
          </label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={handleDescriptionChange}
            className="w-full px-3 py-2 border rounded-md"
            modules={AddTemplate.modules}
            formats={AddTemplate.formats}
            bounds=".app"
            placeholder="Tulis deskripsi disini..."
          />
        </div>
        <div className="mb-4">
          <label htmlFor="tags" className="block text-sm font-bold mb-2">
            Tags
          </label>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={tags}
            onChange={(event, newValue) => setTags(newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Tulis tag, tekan enter untuk tambah"
              />
            )}
          />
        </div>
        {isLoading ? (
          <Loading width={90} height={90} />
        ) : (
          <button
            type="submit"
            className="bg-blue-500 text-white w-full px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Tambah Template
          </button>
        )}
      </form>
    </div>
  );
}

AddTemplate.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
    ['code-block'],
  ],
};

AddTemplate.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
  'video',
  'code-block',
];

export default AddTemplate;
