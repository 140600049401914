import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const RecordingContext = createContext();

export const useRecordingContext = () => useContext(RecordingContext);

export const RecordingProvider = ({ children }) => {

    const [recordings, setRecordings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [count, setCount] = useState(0);
    const urlShowRecordingToday = process.env.REACT_APP_URL_SHOW_RECORDING_TODAY;
    const urlCountRecording = process.env.REACT_APP_URL_COUNT_RECORDING
  
    // Buat fungsi untuk mengambil dan memperbarui data rekaman
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Ambil token dari local storage atau sumber lainnya
        
        // Lakukan permintaan GET ke endpoint /show-recording untuk mengambil data rekaman
        const recordingResponse = await axios.get(urlShowRecordingToday, {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        const recordingData = recordingResponse.data;
        setRecordings(recordingData);
  
        // Lakukan permintaan GET ke endpoint /count-recordings untuk mengambil jumlah rekaman
        const countResponse = await axios.get(urlCountRecording, {
          headers: {
            Authorization: `${token}`, 
          },
        });
  
        const countData = countResponse.data;
        setCount(countData.totalRecordings);
  
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch recordings');
        setLoading(false);
        console.error('Error fetching recording data:', error);
      }
    };
  

return (
    <RecordingContext.Provider value={{
        recordings,
        loading,
        error,
        count,
        fetchData,
    }}>
        {children}
    </RecordingContext.Provider>
    );
}
    