import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { fetchTemplateHistory } from "../../service/TemplateService";
import TooltipWrapper from "../UI/TooltipWrapper";
import { sortHistoryByOldest } from "../../Tool/SortUtils";
import { formatDate } from "../../Tool/formatDate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loading from '../UI/Loading'

Modal.setAppElement("#root"); // Set this according to your app's root element

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1000, // Pastikan modal ini berada di atas elemen lainnya
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "80vw", // Atur lebar modal
    height: "60vh", // Atur tinggi modal
  },
};

const HistoryModal = ({ templateId, isOpen, onRequestClose }) => {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (isOpen && templateId) {
      fetchTemplateHistory(templateId)
        
        .then((data) => {
          const sortedData = sortHistoryByOldest(data);
          setHistory(sortedData || []); // Menyimpan array data langsung ke state
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.error("Error fetching history:", error);
          setError("Failed to fetch history");
        });
    }
  }, [isOpen, templateId]);

  const handleCopyToClipBoard = (text) => () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard");
      })
      .catch((error) => {
        console.error("Could not copy text: ", error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Template History"
      style={customStyles}
    >
      <h2 className="text-2xl mb-4  ">Template History</h2>
      {error && <p className="text-red-500">{error}</p>}
      {isLoading ? (<Loading width={96} height={96}/>):(
        <ul>
        {history.length > 0 ? (
          history.map((entry) => (
            <div
              key={entry._id}
              className="bg-white rounded-xl hover:shadow-2xl shadow-lg relative mb-4"
            >
              <div className=" ">
                <div className="flex justify-between pb-4">
                  <div>
                    {entry.changes.map((change) => (
                      <div key={change._id} className="p-3">
                        <h2 className="text-xl font-bold mb-2">
                          {change.title}
                        </h2>
                        <ReactQuill
                          value={change.description}
                          readOnly={true}
                          theme={"bubble"}
                        />
                         
                      </div>
                    ))}
                  </div>
                  <div className="pl-2 pr-2 pt-5">
                    <TooltipWrapper id="tooltip-copy" tooltipContent="Copy">
                      <button
                        onClick={handleCopyToClipBoard(
                          entry.changes
                            .map(
                              (change) =>
                                `${change.title}\n${change.description}`
                            )
                            .join("\n")
                        )}
                        className="font-bold h-full top-9 sticky text-gray-700 hover:text-black"
                      >
                        <svg
                          className="w-6 h-6 text-black-800"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z"
                          />
                        </svg>
                      </button>
                    </TooltipWrapper>
                  </div>
                </div>
                <div className="bg-gray-100 px-2 py-2 flex justify-between divide-x-2 items-center">
                  {entry.changes.map((change) => (
                    <p className="text-xs text-gray-500 max-w-full">
                      Edited by {change.last_editor}, last edited on{" "}
                      {formatDate(entry.timestamp)}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1>Tidak ada history</h1>
        )}
      </ul>
      ) 
      
      }
      <button
        onClick={onRequestClose}
        className="mt-4 px-4 py-2 bg-gray-800 text-white rounded"
      >
        Close
      </button>
    </Modal>
  );
};

export default HistoryModal;
