import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useNotification } from "../../Store/NotificationContext"


const TooltipWrapper = ({ content, children }) => (
  <Tippy content={content} placement="auto">
    {children}
  </Tippy>
);

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};


const UrlList = ({ urls, onEdit, onDelete }) => {
  const { showNotification } = useNotification();

  const handleCopyToClipBoard = (text) => () => { 
    navigator.clipboard.writeText(text).then(
      () => {
        showNotification("URL tersalin", "success");
      },
      (err) => {
        showNotification("Gagal menyalin URL", "error");
      }
    );
  };
  
  return (
    <div className="p-4 bg-white rounded shadow-md mt-4">
      <h2 className="text-2xl mb-4">URL List</h2>
      <ul>
        {urls.map((url) => (
          <li
            key={url._id}
            className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4"
          >
            <div className="flex-1 min-w-0">
              <h3 className="text-xl truncate">{url.judul}</h3>
              <TooltipWrapper content={url.url}>

              <p onClick={handleCopyToClipBoard(url.url)}  className="text-gray-600 truncate w-full md:w-auto md:max-w-xs overflow-hidden">
                {truncateText(url.url, 50)}{" "}
                {/* Batasi URL menjadi 50 karakter */}
              </p>
              </TooltipWrapper>
            </div>
            <div className="flex pl-4 space-x-4 mt-2 md:mt-0">
              <TooltipWrapper content="Kunjungi link">
              <a href= {url.url}  target="_blank"  rel="noopener noreferrer " className="mt-1 w-8">
                <svg 
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
              </a>
              </TooltipWrapper>
              <TooltipWrapper content="Edit URL">
              <button
                onClick={() => onEdit(url)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-300"
              >
                Edit
              </button>
              </TooltipWrapper>
              <TooltipWrapper content="Hapus URL"> 
              <button
                onClick={() => onDelete(url._id, url.judul)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors duration-300"
              >
                Delete
              </button>
              </TooltipWrapper>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UrlList;
