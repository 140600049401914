import React from 'react';
import PasswordChecklist from "react-password-checklist";

const PasswordValidator = ({ password, confirmPassword, onChange }) => {
  return (
    <PasswordChecklist
      rules={["minLength", "specialChar", "number", "capital", "match"]}
      minLength={5}
      value={password}
      valueAgain={confirmPassword}
      messages={{
        minLength: "Minimal 5 karakter",
        specialChar: "Memiliki spesial karakter",
        number: "Memiliki angka",
        capital: "Memiliki huruf kapital",
        match: "Confirm password",
      }}
      onChange={onChange}
    />
  );
};

export default PasswordValidator;
