import React, { useEffect } from 'react'
import FormRerocding from '../components/Recording/FormRecording'
import Tabs from '../components/Recording/Tabs'  
import {  useNavigate } from "react-router-dom";
import {getCookie} from '../Tool/CookieUtils'
import useDocumentTitle from '../hooks/useDocumentTitle';

export default function Recoring() {

  const token = getCookie("token");
  const navigate = useNavigate();
  useDocumentTitle('Recording')
  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      navigate("/login");
      return;
    } 
},[token, navigate]); 

  return (
    <div className="min-h-screen w-full">
      <FormRerocding/>   
      <div className="px-5 py-10"> 
      <Tabs/>
      </div> 
      
    </div>
  )
}
