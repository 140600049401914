import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Homepage from "./components/Homepage";
import Register from "./components/Register";
import UserProfile from "./components/UserProfile";
import EditTemplate from "./Pages/EditTemplate";
import LogUsers from "./Pages/LogUsers";
import AddTemplate from "./Pages/AddTemplate";
import PageNotFound from "./Pages/PageNotFound";
import Contoh from "./Pages/Contoh";
import Recording from "./Pages/Recording";
// import Clock from "./components/Clock";
import UnauthorizedPage from "./Pages/UnauthorizedPage";
import ForgotPassword from "./Pages/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import ResetPasswordForm from './Pages/ResetPasswordForm.js';
import URLControl from './Pages/URLControl'

//protected superadmin route
import SuperAdminRoute from './components/SuperAdminRoute';

import Notification from "./components/UI/Notification";

// context
import { AuthProvider } from "./Store/AuthContext";
import { MessageProvider } from "./Store/MessageContext";
import { RecordingProvider } from "./Store/RecordingContext";
import { TriggerProvider } from "./Store/TriggerContext";
import { DateProvider } from "./Store/DateContext";
import { NotificationProvider } from "./Store/NotificationContext";
import { ModalProvider } from "./Store/NavbarModalStyleContext.js";
import {useModal}  from "./Store/NavbarModalStyleContext.js";
//CSS
import 'react-toastify/dist/ReactToastify.css'; 
import './CSS/modalEditUser.css'

const AppContent = () => {
  const { isModalOpen } = useModal();
  

  return (
    <div>
      <Navbar />
      <div className={`${isModalOpen ? "blur" : ""} flex items-center justify-normal`}>
        <Notification />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          <Route path="/edit/:id" element={<ProtectedRoute requiredRoles={['admin', 'superadmin']}><EditTemplate /></ProtectedRoute>} />
          <Route path="/tambah" element={<ProtectedRoute requiredRoles={['admin', 'superadmin']}><AddTemplate /></ProtectedRoute>} />
          <Route path="/log-user" element={<SuperAdminRoute><LogUsers /></SuperAdminRoute>} />
          <Route path="/contoh" element={<ProtectedRoute requiredRoles={['superadmin']}><Contoh /></ProtectedRoute>} />
          <Route path="/recording" element={<ProtectedRoute><Recording /></ProtectedRoute>} />
          <Route path="/unauthorized" element={<ProtectedRoute><UnauthorizedPage /></ProtectedRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:token" element={<ResetPasswordForm />} />
          <Route path="/url-penting" element={<ProtectedRoute requiredRoles={['superadmin']}><URLControl /></ProtectedRoute>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <NotificationProvider>
        <DateProvider>
          <RecordingProvider>
            <MessageProvider>
              <AuthProvider>
                <TriggerProvider>
                  <ModalProvider>
                    <AppContent />
                  </ModalProvider>
                </TriggerProvider>
              </AuthProvider>
            </MessageProvider>
          </RecordingProvider>
        </DateProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;