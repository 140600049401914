import React, { useState } from 'react';
import urlService from '../../service/UrlService'; 
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const TooltipWrapper = ({ content, children }) => (
  <Tippy content={content} placement="auto">
    {children}
  </Tippy>
);

const CreateUrl = ({ fetchUrls }) => {
  const [judul, setJudul] = useState('');
  const [url, setUrl] = useState(''); 
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    const createURLPromise = urlService.createUrl({ judul, url })
    toast.promise(createURLPromise,{
        pending:'Mencatat URL Baru', 
        success:'URL Berhasil Tercatat', 
        error:{
            render({data}){
                return data?.response?.data?.message || 'Error mencatat URL';
            }
        }
    })
   
    try {
      await createURLPromise; 
      setJudul('');
      setUrl('');
      fetchUrls(); 
    } catch (error) { 
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl mb-4">Create URL</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <input
            type="text"
            placeholder="Judul"
            value={judul}
            maxLength={50}
            onChange={(e) => setJudul(e.target.value)}
            className="border p-2 w-full rounded"
            required
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="border p-2 w-full rounded"
            required
          />
        </div>
        <TooltipWrapper content="Buat URL Baru">
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Create</button>
        </TooltipWrapper>
      </form>
    </div>
  );
};

export default CreateUrl;
