import React, { useState, useEffect } from "react";
import { getTotalRecordingByDateMonthYear, getRecordingYear } from "../../service/RecordingService";
import Loading from "../UI/Loading";
import { useNotification } from "../../Store/NotificationContext";
import { months } from "../../Tool/DateUtils"; // Buat file utilitas untuk bulan

const ShowRecordingMonth = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()+1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());
  const { showNotification } = useNotification();
  const [recordingThisMonth, setRecordingThisMonth] = useState(null);
  const [recordingThisYear, setRecordingThisYear] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      try {
        const data = await getTotalRecordingByDateMonthYear(
          selectedDay,
          selectedMonth,
          selectedYear
        );
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setError("Error Fetch Data");
        showNotification("Error fetching records.");
        setLoading(false);
      }
    };

    fetchRecords();
  }, [selectedMonth, selectedYear, selectedDay, showNotification]);


  useEffect(()=>{
   const currentYear = new Date().getFullYear(); 
   const fetchAmountRecordingYear = async () => {
    try {
      const data = await getRecordingYear (currentYear);
      setRecordingThisYear(data)
    } catch (error) {
      console.error(error)
    }
   }

   fetchAmountRecordingYear()
  })

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(5), (val, index) => currentYear - index); // Menampilkan 10 tahun terakhir
  const days = Array.from(new Array(31), (val, index) => index + 1); // Menampilkan hari dari 1 hingga 31



  return (
    <div className="min-h-screen flex flex-col items-center">
      {error && (
        <p className="text-center text-white py-2 rounded-md shadow-2xl bg-red-700 w-full flex items-center justify-center">
          {error}
        </p>
      )}

      <div className="flex w-full  justify-start mb-4">
        <div className="flex space-x-4">
          <div>
            <label htmlFor="day" className="block text-gray-700 font-bold mb-2">
              Day
            </label>
            <select
              id="day"
              value={selectedDay}
              onChange={handleDayChange}
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="month"
              className="block text-gray-700 font-bold mb-2"
            >
              Month
            </label>
            <select
              id="month"
              value={selectedMonth}
              onChange={handleMonthChange}
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="year"
              className="block text-gray-700 font-bold mb-2"
            >
              Year
            </label>
            <select
              id="year"
              value={selectedYear}
              onChange={handleYearChange}
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>  
      </div>
     <div className="flex flex-row-reverse w-full pb-5">
     <div className="  py-4 px-4   bg-slate-200 rounded-md shadow-lg  ml-4 ">Total Tahun ini : {recordingThisYear}</div>
      <div className="  py-4 px-4  bg-slate-200 rounded-md shadow-lg ml-4">Total bulan ini : {recordingThisMonth}</div>

     </div>
      {isLoading && !error ? (
        <Loading width={90} height={90} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
          {records.map((record) => (
            <div key={record._id} className="bg-white p-4 rounded shadow-md">
              <p>
                <strong>Nama user : </strong>
                {record.nama_user}
              </p>
              <p>
                <strong>PrivyID:</strong> {record.privyid}
              </p>
              <p>
                <strong>Nomor Telepon:</strong> {record.nomor_telepon}
              </p>
              <p>
                <strong>Email:</strong> {record.email}
              </p>
              <p>
                <strong>Jenis Case:</strong> {record.jenis_case}
              </p>
              
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowRecordingMonth;
