import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import "../CSS/modalEditUser.css";
import { updateMyData } from '../service/UsersService';
import { toast } from "react-toastify";


Modal.setAppElement('#root'); // ini untuk aksesibilitas

const EditUserModal = ({ isOpen, onRequestClose, currentUser, onSave }) => {
  const [userDetails, setUserDetails] = useState(currentUser || {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (currentUser) {
      setUserDetails(currentUser);
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    const updateMyDataPromise = updateMyData(userDetails._id, userDetails); 

    toast.promise(updateMyDataPromise,{
        pending:'Update user ...',
        success: 'Data berhasil terupdate',
        error:{
            render({data}){
                return data?.response?.data?.message || 'Error menambahkan template';
            }
        }
    })

    try {
      await 
      onSave(userDetails); 
      onRequestClose(); 
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Error updating user. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit User Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="p-6 bg-white w-96 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Edit User Profile</h2>
        <form onSubmit={handleSubmit}>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <div className="mb-4">
            <label className="block text-gray-700">Username</label>
            <input
              type="text"
              name="username"
              value={userDetails.username || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={userDetails.email || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Inisial</label>
            <input
              type="text"
              name="inisial"
              value={userDetails.inisial || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onRequestClose}
              className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-dark-primary text-white py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditUserModal;
