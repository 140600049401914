import React, { useState } from "react";
import { useAuth } from "../Store/AuthContext";
import Alert from "./Alert";
import Notification from "../components/UI/Notifikasi";
import { Link } from "react-router-dom";
import Loading from "../components/UI/Loading"
import useDocumentTitle from "../hooks/useDocumentTitle";

const Login = () => {
  const { login, error, setError} = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false)
 
  useDocumentTitle('Login')
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await login(username, password);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      
    }
  };

  const handleCloseAlert = () => {
    setError("") // Kosongkan pesan kesalahan saat notifikasi ditutup
     
  };

  return (
    <div className="mx-auto mt-10 p-6 w-96 sm:w-[900] rounded-lg shadow-lg hover:shadow-xl bg-white">
    <h2 className="text-3xl font-semibold mb-6 text-gray-800">Login</h2>
  
    {/* Notifikasi kesalahan */}
    {error && <Alert errors={error} onClose={handleCloseAlert} />}
  
    <form onSubmit={handleLogin} className="space-y-4">
      <div>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full px-4 py-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
        />
      </div>
  
      <div>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
        />
      </div>
  
      <div>
        {isLoading ? (
          <div className="flex justify-center">
            <Loading width={72} height={72} />
          </div>
        ) : (
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            Login
          </button>
        )}
      </div>
    </form>
  
    <div className="flex justify-between text-sm py-4 mt-4 border-t border-gray-200">
      <div>
        Belum punya akun?{' '}
        <Link
          to="/register"
          className="text-indigo-600 hover:text-indigo-700 transition duration-300"
        >
          Register
        </Link>
      </div>
      <div>
        <Link
          to="/forgot-password"
          className="text-indigo-600 hover:text-indigo-700 transition duration-300"
        >
          Lupa password?
        </Link>
      </div>
    </div>
  
    {/* Notifikasi tambahan */}
    <div className="py-5">
      {error && <Notification message={error} onClose={handleCloseAlert} />}
    </div>
  </div>
  
  );
};

export default Login; 