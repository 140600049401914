import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Masonry from 'react-masonry-css';

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const LoadingSkeletonCards = () => {
  const cardCount = getRandomNumber(5, 10);

  const dummySkeleton = {
    default: 3,
    1100: 2,
    700: 1,
    500: 1,
  };

  return (
    <div className="grid w-full">
      <Masonry
        breakpointCols={dummySkeleton}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {Array.from({ length: cardCount }, (_, index) => {
          const randomHeight = getRandomNumber(150, 300);
          return (
            <div key={index} className="bg-white rounded-xl shadow-lg p-4">
              <div className="mb-4">
                <Skeleton height={30} width="80%" />
              </div>
              <div className="mb-2">
                <Skeleton height={randomHeight} width="100%" />
                <Skeleton height={20} width="95%" />
              </div>
              <div className="flex justify-between items-center mt-4">
                <Skeleton circle={true} height={40} width={40} />
                <Skeleton height={30} width="40%" />
              </div>
            </div>
          );
        })}
      </Masonry>
    </div>
  );
};

export default LoadingSkeletonCards;
