const monthNames = [
    "Januari", "Februari", "Maret", "April", "Mey", "Juni",
    "July", "Agustus", "September", "Oktober", "November", "Desember"
  ];
  
  export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };