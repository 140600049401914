import React from 'react'; 
import AlertAnimation from '../components/UI/AlertAnimation'
import useDocumentTitle from '../hooks/useDocumentTitle';

const UnauthorizedPage = () => {

  useDocumentTitle('Unauthorized Page')
  return (
    <div className="flex flex-col items-center justify-center ">
       <AlertAnimation width={96} height={96} />
      <h1 className="mb-4 text-red-600 text-6xl ">401</h1>
      <p className="text-gray-700 text-2xl">Anda tidak memiliki izin untuk melihat halaman ini.</p>
    </div>
  );
};

export default UnauthorizedPage;