import React, { useState } from 'react';
import { forgotPasswordUser } from '../service/ForgotPassword';
import { toast } from "react-toastify";
import useDocumentTitle from '../hooks/useDocumentTitle';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    // eslint-disable-next-line
    const [message, setMessage] = useState('');
    // eslint-disable-next-line
    const [error, setError] = useState('');


    useDocumentTitle('Forgot Password')
    const handleSubmit = async (event) => {
        event.preventDefault();  
        const forgotPasswordPromise= forgotPasswordUser(email);

        toast.promise(forgotPasswordPromise, {
        pending: 'Mengirimkan email ...',
        success: 'Email reset password terkirim',
        error: {
            render({ data }) {
            return data?.response?.data?.message || 'Email gagal terkirim';
            }
        }
        }); 
        try {
        await forgotPasswordPromise;

        } catch (err) { 

        }
    };

    return (
        <div className="flex items-center justify-center w-full px-5">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-2xl font-bold mb-5">Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    {message && <p className="text-green-500 mb-4">{message}</p>}
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <button
                        type="submit"
                        className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Send Reset Link
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
