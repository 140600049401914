// src/components/AddRecord.js
import React, { useState } from "react"; 
import { addRecord } from "../../service/RecordingService";
import Loading from "../UI/Loading";
import {useTrigger} from "../../Store/TriggerContext"
import {
  useNotification
} from "../../Store/NotificationContext";

const AddRecord = () => {
  const [formData, setFormData] = useState({
    nama_user: "",
    privyid: "",
    nomor_telepon: "",
    email: "",
    jenis_case: "",
    status_recording: false,
  });
  
  const {handleTrigger} = useTrigger()
  const [isLoading, setLoading ] = useState(false)
  const { showNotification } = useNotification();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    handleTrigger()
    try {
      await addRecord(formData);
      setLoading(false)
      showNotification('Berhasil menambahkan recording','success')
      setTimeout(() => {
        showNotification('','')
      }, 3000);
    } catch (error) {
      setLoading(false) 
      showNotification( error.response?.data?.message,'error' || "Failed to delete template",
        "error")
      setTimeout(() => {
        showNotification('','')
      }, 3000);
    }
  };

 
  return (
    <div className=" flex items-center px-5 py-5 justify-center">
      <div className="bg-white p-8  rounded shadow-md w-full">
        <h1 className="text-2xl font-bold mb-6">Add Record</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Nama User</label>
            <input
              type="text"
              name="nama_user"
              value={formData.nama_user}
              disabled={isLoading}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">PrivyID</label>
            <input
              type="text"
              name="privyid"
              value={formData.privyid}
              disabled={isLoading}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Nomor Telepon</label>
            <input
              type="text"
              name="nomor_telepon"
              disabled={isLoading}
              value={formData.nomor_telepon}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              disabled={isLoading}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Jenis Case</label>
            <input
              type="text"
              name="jenis_case"
              disabled={isLoading}
              value={formData.jenis_case}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          {isLoading ? (
            <div> <Loading width={60} height={60}/> </div>
          ) : (
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              Tambah Record
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
 

export default AddRecord;
