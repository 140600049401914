import React, { useState, useEffect } from 'react';
import urlService from '../../service/UrlService'; 
import {toast} from 'react-toastify'


 
const UpdateUrl = ({ currentUrl, fetchUrls, clearCurrentUrl }) => {
  const [judul, setJudul] = useState('');
  const [url, setUrl] = useState(''); 

  useEffect(() => {
    if (currentUrl) {
      setJudul(currentUrl.judul);
      setUrl(currentUrl.url);
    }
  }, [currentUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateURLPromise = urlService.updateUrl(currentUrl._id, { judul, url });
    toast.promise(updateURLPromise, {
      pending: 'Mengupdate URL', 
      success: 'URL berhasil terupdate',
      error: {
        render({ data }) {
          return data?.response?.data?.message || 'Error update URL';
        }
      }
    });
    try {
      await updateURLPromise;
      setJudul('');
      setUrl('');
      fetchUrls();
      clearCurrentUrl(); 
    } catch (error) {
      console.error("Error updating URL:", error);
    }
  };

  const handleCancel = () => {
    setJudul('');
    setUrl('');
    clearCurrentUrl();
  };

  if (!currentUrl) return null;

  return (
    <div className="p-4 bg-white rounded shadow-md mt-4">
      <h2 className="text-2xl mb-4">Update URL</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <input
            type="text"
            placeholder="Judul"
            value={judul}
            onChange={(e) => setJudul(e.target.value)}
            className="border p-2 w-full rounded"
            required
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="border p-2 w-full rounded"
            required
          />
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">Update</button>
          <button type="button" onClick={handleCancel} className="bg-gray-500 text-white p-2 rounded">Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUrl;