import axios from "axios";
import { getCookie } from "../Tool/CookieUtils";

const API_URL = process.env.REACT_APP_LOCAL_HOST;
const base_url = process.env.REACT_APP_URL_BASE_RECORDING;
const urlYear = process.env.REACT_APP_URL_RECORD_YEAR ;

export const addRecord = async (formData) => {
    const token = getCookie('token');
    try {
      const response = await axios.post(
        API_URL+base_url,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding record:', error);
      throw error;
    }
  };


  export const getTodayRecords = async () => {
    const token = getCookie('token');
    try {
      const response = await axios.get(
        `${API_URL+base_url}/show-record-today`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching today records:', error);
      throw error;
    }
  }; 

  export const getTotalRecording = async () => {
    const token = getCookie('token');
    try {
      const response = await axios.get(
        `${API_URL+base_url}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
      console.error('Error fetching today records:', error);
      throw error;
    }
  }; 
 
  export const getTotalRecordingByDateMonthYear = async (day, month, year) => { 
    const URL_RECORD = ` ${API_URL+base_url}/recordings?day=${day}&month=${month}&year=${year}`
     
    const token = getCookie('token');
    try{
      const response = await axios.get(
        URL_RECORD,
         { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data
    }catch(error){
      console.error('error fetching data by date month year : ', error )
    }
  };  


  export const updateRecord = async(recordId, newStatus)=>{
    const token = getCookie('token');
    try{
      const response = await axios.put(
       ` ${API_URL+base_url}/${recordId}`, newStatus,
       {headers:{Authorization:`Bearer ${token}`}}
      );
      return response.data
    }catch(error){
      console.log("error update recording : ", error)
    }
  }


export const getRecordingYear = async(year) =>{ 
  const token = getCookie('token');
  try {
    const response = await axios.get(
      `${API_URL + urlYear}/${year}`, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.totalRecordings
  } catch (error) {
    console.error("error get length recording yearly")
  }
}