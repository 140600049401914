import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ id, content, position }) => {
  return (
    <div
      id={id}
      role="tooltip"
      className={`absolute z-10 invisible group-hover:visible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:opacity-100 ${position}`}
    >
      {content}
      <div className="tooltip-arrow" data-popper-arrow></div>
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  position: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'bottom-full left-1/2 transform -translate-x-1/2 mt-2', // Default position is below the button
};

export default Tooltip;
