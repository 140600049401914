import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip'; 

const TooltipWrapper = ({ id, tooltipContent, children, position }) => {
  return (
    <div className="relative group inline-block w-full">
      {children}
      <Tooltip id={id} content={tooltipContent} position={position} />
    </div>
  );
};

TooltipWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
};

TooltipWrapper.defaultProps = {
  position: 'bottom-full left-1/2 transform -translate-x-1/2 mt-2', // Default position is below the button
};

export default TooltipWrapper;
