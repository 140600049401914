import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getTemplateById,
  updateTemplate,
  deleteTemplate,
} from "../service/TemplateService";
import { useNotification } from "../Store/NotificationContext";
import Loading from "../components/UI/Loading";
import ConfirmationPopup from "../components/UI/ConfirmationPopup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { TextField, Autocomplete, Chip } from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";

const EditTemplate = () => {
  const [isLoading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [template, setTemplate] = useState({
    title: "",
    description: "",
    tags: [],
  });
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [disableText, setDisableText] = useState(true);

  useDocumentTitle("Edit - Template");

  useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const data = await getTemplateById(id);
        setTemplate(data);
        setLoading(false);
      } catch (error) {
        showNotification(
          error.response?.data?.message || "Failed to fetch data",
          "error"
        );
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [id, showNotification]);

  const handleChange = (name, value) => {
    setDisableText(false);
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }));
  };

  const handleQuillChange = (content) => {
    handleChange("description", content);
  };

  const handleTagChange = (event, newTags) => {
    setDisableText(false);
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      tags: newTags,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updateTemplatePromise = updateTemplate(id, template);
    toast.promise(updateTemplatePromise, {
      pending: "Update Template . . . ",
      success: "Berhasil Update Template.",
      error: {
        render({ data }) {
          return data?.response?.data?.message || "Error menambahkan template";
        },
      },
    });

    try {
      await updateTemplatePromise;
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    setShowPopup(false);
    setLoading(true);

    const deleteTemplatePromise = deleteTemplate(id);
    toast.promise(deleteTemplatePromise, {
      pending: "Menghapus template...",
      success: "Template berhasil dihapus!",
      error: {
        render({ data }) {
          return data?.response?.data?.message || "Error menambahkan template";
        },
      },
    });
    try {
      await deleteTemplatePromise;
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(
        error.response?.data?.message || "Failed to delete template",
        "error"
      );
    }
  };

  const handleCancelDelete = () => {
    setShowPopup(false);
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center p-4">
      <h1 className="text-2xl font-bold mb-6">Edit Template</h1>
      {error && <p className="text-red-500">{error}</p>}
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-2xl bg-white p-12 rounded shadow-md"
      >
        <div className="mb-4">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            disabled={isLoading}
            value={template.title}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            className={`w-full px-3 py-2 border border-gray-300 rounded ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-gray-700 font-bold mb-2"
          >
            Description
          </label>
          <ReactQuill
            theme="snow"
            value={template.description}
            onChange={handleQuillChange}
            className="w-full px-3 py-2 border rounded-md"
            modules={EditTemplate.modules}
            formats={EditTemplate.formats}
            bounds=".app"
            placeholder="Tulis deskripsi disini..."
          />
        </div>
        <div className="mb-4">
          <label htmlFor="tags" className="block text-sm font-bold mb-2">
            Tags
          </label>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={template.tags}
            onChange={handleTagChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Tulis tag, tekan enter untuk tambah"
              />
            )}
          />
        </div>
        {isLoading ? (
          <Loading width={70} height={70} />
        ) : (
          <div className="flex flex-col sm:flex-row justify-normal sm:justify-between items-center pt-5">
            <div className="grid gap-1 grid-cols-1 sm:grid-cols-2 w-full">
              <button
                type="submit"
                disabled={disableText}
                className={`bg-blue-500 w-full sm:w-56 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                  isLoading || disableText
                    ? "cursor-not-allowed bg-gray-700 hover:bg-gray-900"
                    : ""
                }`}
              >
                Update Template
              </button>

              <Link to={"/"}>
                <button className="mt-2 sm:mt-0 sm:w-24 w-full bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded">
                  Batal
                </button>
              </Link>
            </div>
            <button
              type="button"
              onClick={handleDeleteClick}
              className="mt-2 sm:mt-0 sm:w-24 w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        )}
      </form>
      {showPopup && (
        <ConfirmationPopup
          message="Ingin menghapus template?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

// Konfigurasi modules dan formats untuk ReactQuill
EditTemplate.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

EditTemplate.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

export default EditTemplate;
