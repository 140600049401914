// src/utils.js

/**
 * Fungsi untuk mengurutkan array data berdasarkan timestamp dari yang terlama hingga terbaru
 * @param {Array} data - Array data yang akan diurutkan
 * @returns {Array} - Array data yang telah diurutkan
 */
export const sortHistoryByOldest = (data) => {
    return data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  };
  