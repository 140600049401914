import React, { createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    message: '',
    type: '',
  });

  const showNotification = useCallback((message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000); // Hide notification after 3 seconds
  }, []);


  const showPromiseNotification = (promise, messages) => {
    toast.promise(
      promise,
      {
        pending: messages.pending,
        success: messages.success,
        error: {
          render({ data }) {
            return data?.response?.data?.message || 'Error menambahkan template';
          }
        }
      },
      {
        className: 'notification-toast',
        progressClassName: 'notification-progress',
      }
    );
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification,showPromiseNotification  }}>
      {children}
    </NotificationContext.Provider>
  );
};
