import React, { useEffect, useState } from 'react';
import ShowRecordingToday from './ShowRecordingToday';
import { format } from 'date-fns';
import { id } from 'date-fns/locale/id'; 
import ShowRecordingMonth from './ShowRecordingMonth';

// Komponen Tab
const Tab = ({ label, isActive, onClick }) => {
  return (
    <div
      className={`cursor-pointer px-4 py-2 ${
        isActive ? 'border-b-2 border-dark-primary' : ''
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

// Komponen Tabs
const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className=" ">
      <div className="flex">
        {children.map((child, index) => (
          <Tab
            key={index}
            label={child.props.label}
            isActive={activeTab === index}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>
      <div>
        {children.map((child, index) =>
          activeTab === index ? (
            <div key={index}>{child.props.children}</div>
          ) : null
        )}
      </div>
    </div>
  );
};
 



const RecordTabs = () => {

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Mengatur tanggal hari ini saat komponen dipasang (mounted)
    const today = new Date();
    const formattedDate = format(today, "EEEE, dd MMMM yyyy", { locale: id });
    setCurrentDate(formattedDate);

    // Membersihkan efek setelah komponen dibongkar (unmounted)
    return () => {
      setCurrentDate('');
    };
  }, []); // useEffect hanya dijalankan sekali setelah komponen dipasang (mounted)

  return (
    <Tabs>
      <div label={`Recording ${currentDate}`} className="p-4 bg-gray-200">
         <div className='pt-5'>
         <ShowRecordingToday/>
         </div>
        
      </div>
      <div label="Total Recording" className="p-4 bg-gray-200">
      <div className='pt-5'>
         <ShowRecordingMonth/>
         </div>
      </div>
    </Tabs>
  );
};

export default RecordTabs;
