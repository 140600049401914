import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Store/AuthContext';

const ProtectedRoute = ({ children, requiredRoles }) => {
  const { isLoggedIn, isLoading, role } = useAuth();
  const location = useLocation();

  if (isLoading) {
    // Tampilkan loading spinner atau kosongkan return untuk sementara
    return <div>Loading...</div>; // atau return null;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRoles && !requiredRoles.includes(role)) {
    return <Navigate to="/unauthorized" replace />;
  }
  
  return children;
};

export default ProtectedRoute;
