import React, { useState, useEffect } from "react";
import { getTodayRecords, updateRecord } from "../../service/RecordingService";
import {useTrigger} from "../../Store/TriggerContext"
import Loading from '../UI/Loading'
import { useNotification} from "../../Store/NotificationContext";

const ShowRecordingToday = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState("");
  const { trigger } = useTrigger();
  const [isLoading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [dataAmount, setAmountData] = useState();

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      try {
        const data = await getTodayRecords();
        // Sort records by last_edit_time in descending order
        const sortedData = data.sort((a, b) => new Date(b.last_edit_time) - new Date(a.last_edit_time));
        setRecords(sortedData);
        setAmountData(sortedData.length);
        setLoading(false);
      } catch (error) {
        setError("Error fetching records.");
        showNotification('Error fetching records.');
        setLoading(false);
      }
    };

    fetchRecords();
  }, [trigger, showNotification]);

  const handleStatusChange = async (recordId, newStatus) => {
    try {
      await updateRecord(recordId, { status_recording: newStatus });
      setRecords((prevRecords) =>
        prevRecords.map((record) =>
          record._id === recordId ? { ...record, status_recording: newStatus } : record
        ).sort((a, b) => new Date(b.last_edit_time) - new Date(a.last_edit_time)) // Re-sort after update
      );
    } catch (error) {
      showNotification('Error updating record.');
    }
  };


  return (
    <div className="min-h-screen flex flex-col items-center  py-1">
      <div className=" flex w-full px-4  my-2 items-start ">Total : {dataAmount}</div>
      {error && (
        <p className="text-center text-white py-2 rounded-md shadow-2xl bg-red-700 w-full flex items-center justify-center">
          {error}
        </p>
      )}
      {isLoading && !error ? (
        <Loading width={90} height={90} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-11/12">
          {records.map((record) => (
            <div
              key={record._id}
              className={`p-6 rounded-lg shadow-lg transition-colors duration-300 ${
                record.status_recording ? 'bg-green-100 border border-green-500' : 'bg-white'
              }`}
            >
              <p className="mb-2">
                <strong>Nama user:</strong> {record.nama_user}
              </p>
              <p className="mb-2">
                <strong>PrivyID:</strong> {record.privyid}
              </p>
              <p className="mb-2">
                <strong>Nomor Telepon:</strong> {record.nomor_telepon}
              </p>
              <p className="mb-2">
                <strong>Email:</strong> {record.email}
              </p>
              <p className="mb-2">
                <strong>Jenis Case:</strong> {record.jenis_case}
              </p>
              <p className="mb-2 flex items-center">
                <strong>Status Recording:</strong>
                <input
                  type="checkbox"
                  className="ml-2"
                  checked={record.status_recording}
                  onChange={(e) => handleStatusChange(record._id, e.target.checked)}
                />
              </p>
               
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowRecordingToday;