import React, { useState, useEffect } from 'react';
import CreateUrl from '../components/ListURL/CreateUrl';
import UrlList from '../components/ListURL/UrlList';
import UpdateUrl from '../components/ListURL/UpdateUrl';
import urlService from '../service/UrlService';  
import {toast} from 'react-toastify'
import ConfirmationPopup from '../components/UI/ConfirmationPopup';
import "../CSS/modalEditUser.css";
import useDocumentTitle from '../hooks/useDocumentTitle';

const URLControl =()=>{
    const [urls, setUrls] = useState([]);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [urlToDelete, setUrlToDelete] = useState(null);
  const [judulUrlToDelete, setJudulUrlToDelete] = useState(null);

  useDocumentTitle("URL Manager ")

  const fetchUrls = async () => {
    try {
      const fetchedUrls = await urlService.getAllUrls();
      setUrls(fetchedUrls);
    } catch (error) {
      console.error("Error fetching URLs:", error);
    }
  };

  const handleEdit = (url) => {
    setCurrentUrl(url);
  };

  const handleDelete = async (id) => {
    const deleteURLPromise = urlService.deleteUrl(id);
    toast.promise(deleteURLPromise, {
      pending: 'Menghapus URL',
      success: 'URL Berhasil Terhapus',
      error: {
        render({ data }) {
          return data?.response?.data?.message || 'Error menghapus URL';
        }
      }
    });
    try {
      await deleteURLPromise;
      fetchUrls();
      setShowConfirmDelete(false);
      setUrlToDelete(null);
    } catch (error) {
      console.error("Error deleting URL:", error);
    }
  };

  useEffect(() => {
    fetchUrls();
  }, []);

  const clearCurrentUrl = () => {
    setCurrentUrl(null);
  };

  const showDeleteConfirm = (id, judul) => {
    setUrlToDelete(id);
    setJudulUrlToDelete(judul)
    setShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    setShowConfirmDelete(false);
    setUrlToDelete(null);
  };

  return (
    <div className="container mx-auto p-4">
      {showConfirmDelete && (
        <ConfirmationPopup
          message={`Yakin ingin menghapus URL ${judulUrlToDelete}`}
          onCancel={cancelDelete}
          onConfirm={() => handleDelete(urlToDelete)}
        />
      )}
      <div className={showConfirmDelete ? "blur" : ""}>
        <h1 className="text-4xl mb-4 text-center">URL Manager</h1>
        <CreateUrl fetchUrls={fetchUrls} />
        <UrlList urls={urls} onEdit={handleEdit} onDelete={showDeleteConfirm} />
        <UpdateUrl currentUrl={currentUrl} fetchUrls={fetchUrls} clearCurrentUrl={clearCurrentUrl} />
      </div>
    </div>
  );
};
  
  export default URLControl;