// Store/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie, eraseCookie } from "../Tool/CookieUtils";
import {jwtDecode} from "jwt-decode"; 

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [success, setSuccess] = useState('')
  const [notification, setNotification] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [authorize, setAuthorize]  = useState(true)

  //URL
  const baseURL = process.env.REACT_APP_LOCAL_HOST;
  const endpointLogin = process.env.REACT_APP_URL_USER_LOGIN;
   

  
  useEffect(() => {
    const token = getCookie("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsLoggedIn(true);
        setCurrentUser(decoded);
        setRole(decoded.role);
      } catch (error) {
        console.error("Token decode error:", error);
        setIsLoggedIn(false);
      }
    }
    setIsLoading(false);  
  }, []);

  const login = async (username, password) => {
    setError(""); 
    try {
      const response = await axios.post(baseURL + endpointLogin, { username, password });
      const { token } = response.data;
      setCookie("token", token, 7);
      const decoded = jwtDecode(token);
      setCurrentUser(decoded);
      setRole(decoded.role);
      setIsLoggedIn(true);
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error.response?.data?.message);
      setError(error.response?.data?.message || "Login gagal, silakan coba kembali");
    }
  };
 
  const logout = () => {
    setIsLoggedIn(false);
    setCurrentUser(null);
    setRole(null);
    eraseCookie("token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{authorize, setAuthorize, isLoggedIn,setSuccess,setError, isLoading, login, logout, currentUser, error, role , success,setNotification, notification}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
