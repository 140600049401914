import React, { createContext, useState, useContext } from 'react';

// Buat konteks
const ModalContext = createContext();

// Provider untuk konteks
export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const turnOnModal = () => setIsModalOpen(true);
  const turnOffModal = () => setIsModalOpen(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, turnOnModal, turnOffModal }}>
      {children}
    </ModalContext.Provider>
  );
};

// Hook untuk menggunakan konteks
export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
