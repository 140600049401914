import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotification } from "../../Store/NotificationContext";
import { ToastContainer, toast, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  const { notification } = useNotification();

  useEffect(() => {
    if (notification.promise) {
      toast.promise(
        notification.promise,
        {
          pending: 'Processing...',
          success: 'Successfully completed!',
          error: {
            render({ data }) {
              return data?.response?.data?.message || 'An error occurred';
            }
          }
        },
        {
          className: 'notification-toast',
          progressClassName: 'notification-progress',
        }
      );
    } else if (notification.message) {
      const type = notification.type || 'info';

      const customToast = ({ closeToast }) => (
        <div className="flex items-center space-x-2">
          <div className="ml-2">{notification.message}</div>
        </div>
      );

      toast[type](customToast, {
        className: 'notification-toast',
        progressClassName: 'notification-progress',
      });
    }
  }, [notification]);

  return (
    <ToastContainer 
      position="top-right" 
      autoClose={5000} 
      hideProgressBar={false} 
      closeOnClick={true} 
      pauseOnHover={true} 
      draggable={true} 
      progress={undefined} 
      theme="light" 
      transition={Zoom} 
    />
  );
};

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'info', 'war']),
};

Notification.defaultProps = {
  type: 'info',
};

export default Notification;
