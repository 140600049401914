import axios from "axios"; 

const API_URL = process.env.REACT_APP_LOCAL_HOST;
const FORGOT_PASSWORD_URL = process.env.REACT_APP_URL_FORGOT_PASSWORD;

export const forgotPasswordUser = async (email) => {
    console.log(API_URL+FORGOT_PASSWORD_URL+email )
    try {
      const response = await axios.post(
        API_URL+FORGOT_PASSWORD_URL, {
            "email" : email,
        }
         
      );
      return response.data;
    } catch (error) {
      console.error('Error adding record:', error);
      throw error;
    }
  };


export  const newPasswordUser = async (password, token) => { 
    try {
      const response = await axios.post(
       ` ${API_URL+FORGOT_PASSWORD_URL}/${token}`, {
          password
        } 
      );
      return response.data;
    } catch (error) {
      console.error('Error adding record:', error);
      throw error;
    }
  };
