import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../Assets/Animation - 404.json'; // Ganti dengan path file JSON animasi Anda

const MyLottieAnimation = ({height, width}) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    return (
      <div>
        <Lottie options={defaultOptions} height={height} width={width} isClickToPauseDisabled={true} />
      </div>
    );
  };
  
  export default MyLottieAnimation;