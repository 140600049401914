// ./Store/TriggerContext.js

import React, { createContext, useState, useContext } from 'react';

const TriggerContext = createContext();

export const useTrigger = () => useContext(TriggerContext);

export const TriggerProvider = ({ children }) => {
  const [trigger, setTrigger] = useState(false);
  const [triggerPesan, setTriggerPesan] = useState(false)

  const handleTrigger = () => {
    setTrigger(!trigger);
    
  };

  const pesanHandler = () => {
    setTriggerPesan(!trigger)
  }

  return (
    <TriggerContext.Provider value={{ trigger, handleTrigger, pesanHandler, triggerPesan }}>
      {children}
    </TriggerContext.Provider>
  );
};
