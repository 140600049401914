import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { newPasswordUser } from "../service/ForgotPassword";
import { toast } from "react-toastify";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import PasswordBar from "../components/UI/PasswordBar";
import PasswordValidator from "../components/UI/PasswordChecklist";
import useDocumentTitle from '../hooks/useDocumentTitle';



const ResetPasswordForm = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false); // Tambahkan state untuk validasi
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  useDocumentTitle('Form Reset Password');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Password tidak cocok");
      return;
    }

    const resetPasswordUserPromise = newPasswordUser(password, token);

    toast.promise(resetPasswordUserPromise, {
      pending: "Mengupdate password ...",
      success: "Update password berhasil",
      error: {
        render({ data }) {
          return data?.response?.data?.message || "Error update password";
        },
      },
    });

    try {
      await resetPasswordUserPromise;
      console.info("200");
    } catch (err) {
      console.error("500");
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-5">Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-3 pr-10 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900"
                required
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </div>
            </div>
            <PasswordBar password={password} />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Password
            </label>
          <div className="relative">
          <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="mt-1 p-2 mb-5 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <EyeOffIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </div>
           
          </div>
          <PasswordValidator
              password={password}
              confirmPassword={confirmPassword}
              onChange={(isValid) => setIsValidPassword(isValid)}
            />
          </div>
          {error && (
            <div className="py-2 bg-red-500 my-2 text-white flex justify-between rounded-md px-2">
              <div>{error}</div>
              <button
                onClick={() => setError("")}
                className="px-3 hover:scale-150"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
          )}
          <button
            type="submit"
            className={`w-full ${
              isValidPassword ? `` : `cursor-not-allowed`
            } bg-indigo-500  text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            disabled={!isValidPassword} // Nonaktifkan tombol jika password tidak valid
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
