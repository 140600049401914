import PasswordStrengthBar from 'react-password-strength-bar'; 

export default function PasswordBar({password}) {
  return (
    <PasswordStrengthBar 
              minLength={5}    
              password={password}
              scoreWords={['Lemah', 'Lemah', 'Okay', 'Bagus', 'Kuat']}
              shortScoreWord="Terlalu pendek"
            />
  )
}
