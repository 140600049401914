// MessageContext.js
import React, { createContext, useState, useContext } from 'react';

// Buat context untuk menangani pesan
const MessageContext = createContext();

// Custom hook untuk mengakses context pesan
export const useMessage = () => useContext(MessageContext);

// Provider untuk menyediakan state dan fungsi untuk pesan
export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Fungsi untuk mengatur pesan
  const handleMessage = (messageText) => {
    setMessage(messageText);
    setIsVisible(true);
  };

  // Fungsi untuk menghapus pesan
  const clearMessage = () => {
    setMessage(null);
    setIsVisible(false);
  };

  return (
    <MessageContext.Provider value={{ message, handleMessage, clearMessage, isVisible }}>
      {children}
    </MessageContext.Provider>
  );
};
