// src/services/TemplateService.js
import axios from "axios";
import {getCookie} from "../Tool/CookieUtils" 


const API_URL = process.env.REACT_APP_LOCAL_HOST ;
const base_url = process.env.REACT_APP_URL_BASE_TEMPLATE;
 

export const addTemplate = async (title, description, tags) => {
  const cookieToken = getCookie("token")
  const response = await axios.post(
    `${API_URL+base_url}`,
    { title, description, tags },
    { headers: { Authorization: `Bearer ${cookieToken}` } }
  );
  return response.data;
};


export const getTemplateById = async (id) => {
  const token = getCookie('token');
  try {
    const response = await axios.get(
      `${API_URL+base_url}/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching template:', error);
    throw error;
  }
};

export const updateTemplate = async (id, templateData) => {
  const token = getCookie('token');
  try {
    const response = await axios.put(
      `${API_URL+base_url}/${id}`,
      templateData, 
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating template:', error);
    throw error;
  }
};

export const deleteTemplate = async (id) => {
  const token = getCookie('token');
  try {
    const response = await axios.delete(
      `${API_URL+base_url}/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting template:', error);
    throw error;
  }
};


export const fetchTemplateHistory = async (templateId) => {
  const token = getCookie('token');
  try {
    const response = await axios.get(`${API_URL}${base_url}/${templateId}/history`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
   
    return response.data;
  } catch (error) {
    console.error('Error fetching template history:', error);
    throw error;
  }
};