import React, { createContext, useContext, useState } from 'react';

const DateContext = createContext();

export const useDate = () => useContext(DateContext);

export const DateProvider = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  return (
    <DateContext.Provider value={{ selectedDate, handleChangeDate }}>
      {children}
    </DateContext.Provider>
  );
};
